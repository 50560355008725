import React from 'react';
import RegistrationForm from '../components/RegistrationForm';

function Registration() {
  return (
    <div className="min-h-screen bg-gray-50">
      <RegistrationForm />
    </div>
  );
}

export default Registration;