import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/index";
import Layout from "./components/Layout/Layout";
import AboutUs from "./pages/AboutUs";
import ITStaffResources from "./pages/ITStaffResources";
import EventPage from "./pages/events";
import EnterprisePage from "./pages/EnterprisePage";
import DataIntegrationPage from "./pages/DataIntegrationPage";
import DataIntegrationETLPage from "./pages/DataIntegrationETLPage";
import Course from "./pages/Course";
import CourseDetails from "./components/course/CourseDetails";
import courses from "./data/courses";
import DataGovernanceSecurityPage from "./pages/DataGovernanceSecurityPage";
import BigDataAnalyticsPage from "./pages/BigDataAnalyticsPage";
import Bi_Tools from "./pages/Bi_Tools";
import Cloud_data_solutions from "./pages/Cloud_data_solutions";
import ML_AI from "./pages/ML_AI";
import RTDP from "./pages/RTDP";
import EmployerDashboard from "./pages/EmployerDashboard";
import DashboardHome from "./pages/DashboardHome";
import RFQManagementPanel from "./pages/RFQManagementPanel";
import DiscoveryCallScheduler from "./pages/DiscoveryCallScheduler";
import ResumeForm from "./components/Resume/ResumeForm";
import ResumeView from "./pages/ResumeView";
import LiveResume from "./components/Resume/preview/LiveResume";
import Registration from "./pages/Registration";

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/talent" element={<ITStaffResources />} />
          <Route path="/events" element={<EventPage />} />
          <Route path="/enterprise" element={<EnterprisePage />} />
          <Route
            path="/big-data-architecture-infrastructure-design"
            element={<DataIntegrationPage />}
          />
          <Route
            path="/data-integration-etl-processes"
            element={<DataIntegrationETLPage />}
          />
          <Route
            path="/data-governance-security"
            element={<DataGovernanceSecurityPage />}
          />
          <Route
            path="/big-data-analytics-advanced-analytics"
            element={<BigDataAnalyticsPage />}
          />
          <Route
            path="/business-intelligence-visualization"
            element={<Bi_Tools />}
          />
          <Route
            path="/Registration"
            element={<Registration />}
          />
           <Route
            path="/cloud-data-solutions"
            element={<Cloud_data_solutions />}
          />
               <Route
            path="/machine-learning-ai-integration"
            element={<ML_AI />}
          />
                     <Route
            path="/real-time-data-processing"
            element={<RTDP />}
          />
          <Route path="/Course" element={<Course />} />
          <Route path="/sign-up" element={<EmployerDashboard />} />
          <Route path="/Dashboard" element={<DashboardHome />} />
          <Route path="/rfq-management" element={<RFQManagementPanel />} />
          <Route path="/call-Scheduler" element={<DiscoveryCallScheduler />} />
          <Route path="/sign-in" element={<ResumeForm />} />
          <Route path="/live-resume" element={<LiveResume />} />


          <Route
            path="/courses/:link"
            element={<CourseDetails courses={courses} />}
          />
          {/* Add additional routes here as needed */}
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
