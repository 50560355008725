import React, { useState } from 'react';
import { Eye, ExternalLink } from 'lucide-react';
import { ResumeData } from '../../types/resume';
import PersonalInfoForm from './form/PersonalInfoForm';
import SkillsForm from './form/SkillsForm';
import WorkExperienceForm from './form/WorkExperienceForm';
import EducationForm from './form/EducationForm';
import ProjectsForm from './form/ProjectsForm';
import ContactForm from './form/ContactForm';
import ResumePreview from './preview/ResumePreview';
import LiveResume from './preview/LiveResume';
import { useNavigate } from 'react-router-dom';

const initialData: ResumeData = {
  personalInfo: {
    name: 'Deepkumar Patel',
    title: 'Full Stack Engineer',
    location: 'Toronto, ON, Canada'
  },
  contactInfo: {
    email: 'deepkumar.patel@email.com',
    phone: '(555) 123-4567',
    linkedin: 'https://linkedin.com/in/deepkumarpatel',
    github: 'https://github.com/deepkumarpatel'
  },
  skills: [
    'Python', 'Node.js', 'HTML', 'CSS', 'JavaScript', 'Flask', 'PHP',
    'Pandas', 'Matplotlib', 'Scikit-Learn', 'NumPy', 'MSSQL', 'PostgreSQL',
    'NoSQL', 'Hadoop', 'ETL', 'PySpark', 'Databricks', 'Visual Studio',
    'Anaconda', 'Jupyter Notebook', 'AWS S3 & Athena', 'Azure Blob Storage',
    'API Development'
  ],
  workExperience: [
    {
      id: '1',
      title: 'Full Stack Developer (Co-op)',
      company: 'Blueblocks Ltd.',
      duration: 'Sept 2024 - Dec 2024',
      description:
        'Architected and deployed scalable RESTful APIs using Node.js and MSSQL, incorporating WebSocket for real-time data handling. Implemented a genealogy algorithm for referral tracking, optimizing multi-level referral relationships. Collaborated with cross-functional teams to integrate React front-end components and enhance the user experience. Improved database efficiency by optimizing schemas and executing high-performance SQL queries. Strengthened application security by implementing robust authentication and authorization protocols.',
      skills: ['Node.js', 'MSSQL', 'WebSocket', 'React']

      },
    {
      id: '2',
      title: 'Junior Backend Developer',
      company: 'Otha Industries Pvt Ltd.',
      duration: 'June 2022 - Nov 2023',
      description:
        'Designed the database schema and data models for the "Paidkiya" application, ensuring optimal structure for efficient data storage and retrieval. Administered the database environment, implementing robust security measures. Executed complex SQL queries to analyze user interactions and transactions, providing insights for strategic improvements.',
      skills: ['Node.js', 'SQL', 'Database Design']

    }
  ],
  education: [
    {
      id: '1',
      degree: 'Big Data Solution Architecture Graduate Certificate',
      institution: 'Conestoga College',
      duration: 'Jan 2024 - Present'
    },
    {
      id: '2',
      degree: 'Bachelor in Computer Applications',
      institution: 'UKA Tarsadia University',
      duration: 'August 2019 - May 2022'
    }
  ],
  projects: [
    {
      id: '1',
      title: 'Geospatial Data Analysis',
      description:
        'Developed a geospatial data analysis pipeline leveraging Selenium for data scraping and PostGIS for spatial data management. Implemented multithreading to boost efficiency and designed dynamic geospatial queries for user-specific results, enhancing app versatility.',
      skills: ['Selenium', 'PostgreSQL', 'PostGIS', 'Multithreading'],
      githubLink: 'https://github.com/deepkumarpatel/geospatial-data-analysis',
      demoLink: 'https://github.com/deepkumarpatel/geospatial-data-analysis'
    },
    {
      id: '2',
      title: 'InSync Influencer Marketing Platform',
      description:
        'Spearheaded the creation of an influencer marketing platform with advanced matching algorithms and real-time analytics. Integrated social media APIs for dynamic content fetching and analysis, enhancing user experience.',
      skills: ['Flask', 'Python', 'JavaScript', 'Instaloader', 'Chart.js'],
      githubLink: 'https://github.com/deepkumarpatel/insync-platform',
      demoLink: 'https://github.com/deepkumarpatel/insync-platform'
    },
    {
      id: '3',
      title: 'OYO Review Dataset Analysis',
      description:
        'Analyzed the OYO Review Dataset to extract insights into the hospitality industry. Built a sentiment analysis model with 90%+ accuracy using machine learning techniques and deployed the model for strategic business applications.',
      skills: ['Python (Beautiful Soup)', 'CSV', 'GitHub', 'Tableau'],
      githubLink: 'https://github.com/deepkumarpatel/oyo-review-analysis',
      demoLink: 'https://github.com/deepkumarpatel/oyo-review-analysis'
    },
    {
      id: '4',
      title: 'VirtualExpo',
      description:
        'Created 3D exhibitions using React.js and Three.js, incorporating real-time weather effects, dynamic lighting, and interactive pop-ups to enhance user engagement.',
      skills: ['React.js', 'Three.js', 'JavaScript', 'CSS', 'Webpack'],
      githubLink: 'https://github.com/deepkumarpatel/virtualexpo',
      demoLink: 'https://github.com/deepkumarpatel/virtualexpo'
    },
    {
      id: '5',
      title: 'Job Listing Data Extraction from Indeed',
      description:
        'Automated job listing extraction using Python, Selenium, and BeautifulSoup. Enhanced the script to upload data to Amazon S3 for secure storage.',
      skills: ['Python', 'Selenium WebDriver', 'BeautifulSoup', 'Amazon S3'],
      githubLink: 'https://github.com/deepkumarpatel/job-listing-extraction',
      demoLink: 'https://github.com/deepkumarpatel/job-listing-extraction'
    },
    {
      id: '6',
      title: 'Spotify ETL Data Pipeline',
      description:
        'Developed an ETL pipeline to extract and transform Spotify listening history into insights. Scheduled daily pipeline runs and planned future enhancements like anomaly detection and personalized recommendations.',
      skills: ['Python', 'Selenium', 'Spotipy', 'Pandas'],
      githubLink: 'https://github.com/deepkumarpatel/spotify-etl',
      demoLink: 'https://github.com/deepkumarpatel/spotify-etl'
    }
  ]
};


export default function ResumeForm() {
  const [data, setData] = useState<ResumeData>(initialData);
  const [showPreview, setShowPreview] = useState(false);
  const [showLive, setShowLive] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setShowPreview(true);
  };
  const navigate = useNavigate();

  const handleLiveView = () => {
    navigate('/live-resume', { state: { data } });
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row gap-8">
          <form onSubmit={handleSubmit} className="flex-1 max-w-4xl mx-auto space-y-8">
            <div className="bg-white shadow rounded-lg p-6 space-y-8">
              <PersonalInfoForm
                data={data.personalInfo}
                onChange={(personalInfo) => setData({ ...data, personalInfo })}
              />
              <SkillsForm
                skills={data.skills}
                onChange={(skills) => setData({ ...data, skills })}
              />
              <WorkExperienceForm
                experiences={data.workExperience}
                onChange={(workExperience) => setData({ ...data, workExperience })}
              />
              <EducationForm
                education={data.education}
                onChange={(education) => setData({ ...data, education })}
              />
              <ProjectsForm
                projects={data.projects}
                onChange={(projects) => setData({ ...data, projects })}
              />
              <ContactForm
                data={data.contactInfo}
                onChange={(contactInfo) => setData({ ...data, contactInfo })}
              />
              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  onClick={handleLiveView}
                  className="inline-flex items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  <ExternalLink className="h-5 w-5 mr-2" />
                  {showLive ? 'Hide Live' : 'Show Live'}
                </button>
                <button
                  type="submit"
                  className="inline-flex items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <Eye className="h-5 w-5 mr-2" />
                  Preview Resume
                </button>
              </div>
            </div>
          </form>

          {/* {showLive && (
            <div className="lg:w-1/2 sticky top-8 h-fit">
              <LiveResume data={data} />
            </div>
          )} */}
        </div>
      </div>
      
      {showPreview && (
        <ResumePreview
          data={data}
          onClose={() => setShowPreview(false)}
        />
      )}
    </div>
  );
}