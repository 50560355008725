import React from "react";
import { FaBell, FaPlus, FaQuestionCircle, FaChartBar, FaUser, FaFileAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

function DashboardHome() {
  return (
    <div className="bg-gray-50 mt-20 min-h-screen">
      {/* Header */}
      <div className="bg-blue-500 text-white py-6 px-6 md:px-12">
        <h1 className="text-3xl md:text-5xl font-bold">Welcome, [Company Name]!</h1>
        <p className="text-lg md:text-xl mt-2">Let’s find the right talent for your needs.</p>
      </div>

      <div className="container mx-auto py-8 px-4 md:px-12">
        {/* Dashboard Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-white shadow-lg rounded-lg p-6 flex items-center">
            <FaFileAlt className="text-blue-500 text-4xl mr-4" />
            <div>
              <h2 className="text-lg font-semibold">Current RFQs</h2>
              <p className="text-gray-600">5 (2 Pending, 2 In Review, 1 Completed)</p>
            </div>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-6 flex items-center">
            <FaUser className="text-blue-500 text-4xl mr-4" />
            <div>
              <h2 className="text-lg font-semibold">Discovery Calls</h2>
              <p className="text-gray-600">3 Scheduled</p>
            </div>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-6 flex items-center">
            <FaBell className="text-blue-500 text-4xl mr-4" />
            <div>
              <h2 className="text-lg font-semibold">Notifications</h2>
              <p className="text-gray-600">3 New Alerts</p>
            </div>
          </div>
        </div>

        {/* Activity Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h2 className="text-xl font-bold mb-4">Recent Activities</h2>
            <ul className="space-y-4">
              <li className="flex items-center">
                <FaFileAlt className="text-blue-500 mr-3" />
                <span>✅ Submitted RFQ #1234 (2 days ago).</span>
              </li>
              <li className="flex items-center">
                <FaFileAlt className="text-blue-500 mr-3" />
                <span>📅 Scheduled Discovery Call with DEF Corp (yesterday).</span>
              </li>
              <li className="flex items-center">
                <FaFileAlt className="text-blue-500 mr-3" />
                <span>💬 Received quote for RFQ #5678 (3 hours ago).</span>
              </li>
            </ul>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-6">
            <h2 className="text-xl font-bold mb-4">Key Metrics</h2>
            <ul className="space-y-4">
              <li className="flex items-center">
                <FaChartBar className="text-blue-500 mr-3" />
                <span>Total RFQs Submitted: <strong>25</strong></span>
              </li>
              <li className="flex items-center">
                <FaChartBar className="text-blue-500 mr-3" />
                <span>Average Response Time: <strong>24 hours</strong></span>
              </li>
              <li className="flex items-center">
                <FaChartBar className="text-blue-500 mr-3" />
                <span>Most Requested Skill: <strong>Project Management</strong></span>
              </li>
            </ul>
          </div>
        </div>

        {/* Quick Actions */}
        <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
          <h2 className="text-xl font-bold mb-4">Quick Actions</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Link to="/call-Scheduler" className="bg-blue-500 text-white py-2 px-4 rounded-lg flex items-center justify-center hover:bg-blue-600 transition duration-300">
              <FaPlus className="mr-2" />
              Book Discovery Call
            </Link>
            <Link to="/rfq-management" className="bg-blue-500 text-white py-2 px-4 rounded-lg flex items-center justify-center hover:bg-blue-600 transition duration-300">
              <FaPlus className="mr-2" />
              Submit New RFQ
            </Link>
          </div>
        </div>

        {/* Help Section */}
        <div className="bg-white shadow-lg rounded-lg p-6">
          <h2 className="text-xl font-bold mb-4">Help Section</h2>
          <p className="text-gray-700">
            Need assistance? Visit our <a href="/faqs" className="text-blue-500 underline">FAQs</a> or{" "}
            <a href="/contact" className="text-blue-500 underline">Contact Support</a>.
          </p>
        </div>
      </div>
    </div>
  );
}

export default DashboardHome;
