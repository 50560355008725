import React, { useState } from "react";
import { Link } from "react-router-dom";

const courses = [
  {
    id: 1,
    title: "Cybersecurity Bootcamp",
    description:
      "Unlock the world of cybersecurity with our 'Cybersecurity Bootcamp: From Hacking Fundamentals to Governance, Risk & Compliance.' This course covers hacking fundamentals, vulnerability assessment, compliance audits, and more.",
    image: "https://via.placeholder.com/300",
    instructors: [
      {
        name: "Instructor 1",
        image: "https://randomuser.me/api/portraits/men/11.jpg",
      },
      {
        name: "Instructor 2",
        image: "https://randomuser.me/api/portraits/women/15.jpg",
      },
    ],
    link: "/courses/cybersecurity-bootcamp",
    batches: [
      { date: "December 7, 2024", status: "Ongoing" },
      { date: "September 14, 2024", status: "Closed Enrollment" },
    ],
    techStacks: [
      "Kali Linux",
      "Splunk",
      "OSSEC",
      "Nikto",
      "CyberArk",
      "Acunetix",
      "Wireshark",
      "Nessus",
      "Burp Suite",
      "Snort",
    ],
    curriculum: [
      "What is Governance, Risk and Compliance? and its significance?",
      "Understanding of Information Security strategy",
      "Asset and Data Management Fundamentals",
      "Risk Assessment Walkthrough- Part1",
      "Risk Assessment Walkthrough- Part2",
      "Practical Risk Analysis",
      "Information Security Controls Identification and Implementation",
      "Overview of Business Continuity Planning and Disaster Recovery Plan",
      "Supply Chain Risk Assessment",
      "Introductory Linux",
      "Hacking Methodology",
      "Reconnaissance and Information Gathering Passive",
      "Scanning and Enumeration",
      "Exploitation Basics",
    ],
  },
  {
    id: 2,
    title: "Google Cloud Engineering",
    description:
      "This introductory course offers a comprehensive overview of Google Cloud Platform (GCP), providing participants with a foundational understanding of cloud computing concepts.",
    image: "https://via.placeholder.com/300",
    instructors: [
      {
        name: "Instructor 3",
        image: "https://randomuser.me/api/portraits/men/23.jpg",
      },
    ],
    link: "/courses/google-cloud-engineering",
    batches: [{ date: "October 20, 2024", status: "Ongoing" }],
    techStacks: [
      "Google Cloud Storage",
      "BigQuery",
      "Cloud Functions",
      "Cloud Run",
      "Firebase",
    ],
    curriculum: [
      "Introduction to Google Cloud Platform",
      "Virtual Machines and Compute Engine",
      "Google Kubernetes Engine Basics",
      "Networking in Google Cloud",
      "Managing Data Storage and Databases",
      "Cloud Monitoring and Troubleshooting",
    ],
  },
  {
    id: 3,
    title: "Azure Data Engineering",
    description:
      "This course provides a comprehensive introduction to Microsoft Azure, a leading cloud computing platform. Learn how to create, manage, and secure data pipelines.",
    image: "https://via.placeholder.com/300",
    instructors: [
      {
        name: "Instructor 4",
        image: "https://randomuser.me/api/portraits/women/22.jpg",
      },
      {
        name: "Instructor 5",
        image: "https://randomuser.me/api/portraits/men/33.jpg",
      },
    ],
    link: "/courses/azure-data-engineering",
    batches: [
      { date: "January 10, 2025", status: "Upcoming" },
      { date: "November 15, 2024", status: "Closed Enrollment" },
    ],
    techStacks: [
      "Azure Data Factory",
      "Azure Synapse",
      "Azure Databricks",
      "Power BI",
      "Azure Cosmos DB",
    ],
    curriculum: [
      "Introduction to Azure Cloud",
      "Building Data Pipelines with Azure Data Factory",
      "Data Analysis using Azure Synapse",
      "Machine Learning with Azure Databricks",
      "Visualization with Power BI",
      "Securing Data with Azure Cosmos DB",
    ],
  },
];
const Courses = () => {
  const [showAll, setShowAll] = useState(false);

  const displayedCourses = showAll ? courses : courses.slice(0, 9);

  return (
    <div className="py-6 bg-gray-50">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-6">Our Featured Skill Programs</h2>
        <p className="text-lg text-gray-600 mb-12">
          Develop new skills by enrolling in these featured programs and uplift
          your career. Our programs come with complete hands-on experience.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {displayedCourses.map((course, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md hover:shadow-lg transition duration-300 p-6 flex flex-col justify-between h-full"
            >
              {/* Course Image */}
              <div>
                <img
                  src={course.image}
                  alt={course.title}
                  className="rounded-lg w-full mb-4"
                />
                {/* Course Title */}
                <h3 className="text-xl font-semibold mb-2">{course.title}</h3>
                {/* Course Description */}
                <p className="text-gray-600 mb-4">{course.description}</p>
              </div>
              {/* Instructors */}
              <div className="flex -space-x-2 overflow-hidden">
                {course.instructors.map((instructor, idx) => (
                  <div key={idx}>
                    <img
                      src={instructor.image}
                      alt={instructor.name}
                      className="inline-block size-10 rounded-full ring-2 ring-white"
                    />
                  </div>
                ))}
              </div>
              {/* View Course Button */}
              <div className="mt-auto">
                <Link
                  to={`${course.link}`}
                  className="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 transition duration-300"
                >
                  View Course
                </Link>
              </div>
            </div>
          ))}
        </div>
        {courses.length > 9 && (
          <button
            onClick={() => setShowAll(!showAll)}
            className="mt-8 bg-blue-500 text-white py-2 px-6 rounded-full hover:bg-blue-600 transition duration-300"
          >
            {showAll ? "Show Less" : "Explore All"}
          </button>
        )}
      </div>
    </div>
  );
};

export default Courses;
