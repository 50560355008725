import React, { useState, useEffect } from "react";
import EventFilter from "../components/events/FilterSidebar";
import ViewToggle from "../components/events/ViewToggle";
import CategoryTabs from "../components/events/CategoryTabs";
import EventCard from "../components/events/EventCard";

const EventPage = () => {
  const [view, setView] = useState("grid");
  const [activeCategory, setActiveCategory] = useState("All");
  const [filters, setFilters] = useState({ topic: "All", language: "All" });
  const [loading, setLoading] = useState(false);
  const [displayedEvents, setDisplayedEvents] = useState([]);
  const [visibleCount, setVisibleCount] = useState(12);

  const topics = ["Tech", "Entrepreneurship", "Data"];
  const languages = ["English", "French", "German"];
  const categories = ["All", "Workshop", "Talk", "Info Session", "Demo Day"];

  const events = [
    // Workshops
    {
      id: 1,
      title: "Hands-On AI and ML Integration: Automate Smarter Decisions",
      description:
        "Learn to build and deploy AI/ML models to integrate into real-world systems. Participants will create basic automation using Python and ML libraries like TensorFlow.",
      category: "Workshop",
      deliveryMode: "Online",
      image: "https://via.placeholder.com/150?text=Workshop",
    },
    {
      id: 2,
      title: "Building Scalable Big Data Systems: A Hands-On Approach",
      description:
        "Learn the principles of designing robust Big Data architectures using Hadoop, Spark, and cloud-based storage solutions.",
      category: "Workshop",
      deliveryMode: "In-Person",
      image: "https://via.placeholder.com/150?text=Workshop",
    },
    {
      id: 3,
      title: "Data Visualization and Dashboards: From Data to Insights",
      description:
        "Build dashboards using tools like Power BI and Tableau to convert complex datasets into clear, actionable visuals.",
      category: "Workshop",
      deliveryMode: "Hybrid",
      image: "https://via.placeholder.com/150?text=Workshop",
    },
    {
      id: 4,
      title: "Cloud-Powered Data Pipelines: Building for Scale",
      description:
        "Learn to create scalable and secure data pipelines using AWS, Azure, or Google Cloud.",
      category: "Workshop",
      deliveryMode: "Online",
      image: "https://via.placeholder.com/150?text=Workshop",
    },
    {
      id: 5,
      title: "Mastering ETL: Transform Your Data, Transform Your Business",
      description:
        "Hands-on training to build ETL pipelines using tools like Talend or Apache NiFi.",
      category: "Workshop",
      deliveryMode: "In-Person",
      image: "https://via.placeholder.com/150?text=Workshop",
    },
    // Talks
    {
      id: 10,
      title: "Future of AI in Automation: Challenges and Opportunities",
      description:
        "A deep dive into how AI is transforming decision-making across industries, led by industry experts.",
      category: "Talk",
      deliveryMode: "Hybrid",
      image: "https://via.placeholder.com/150?text=Talk",
    },
    {
      id: 11,
      title: "The Blueprint for Future-Proof Big Data Systems",
      description:
        "Gain insights into trends, tools, and strategies for building scalable Big Data architectures.",
      category: "Talk",
      deliveryMode: "In-Person",
      image: "https://via.placeholder.com/150?text=Talk",
    },
    // Info Sessions
    {
      id: 17,
      title: "AI/ML for Everyone: From Theory to Application",
      description:
        "A beginner-friendly session explaining how AI and ML technologies can be adopted and scaled in business settings.",
      category: "Info Session",
      deliveryMode: "Online",
      image: "https://via.placeholder.com/150?text=Info+Session",
    },
    {
      id: 18,
      title: "Introduction to Big Data Frameworks and Tools",
      description:
        "Explore key frameworks like Apache Kafka, Hadoop, and Spark, and how they enable Big Data processing.",
      category: "Info Session",
      deliveryMode: "In-Person",
      image: "https://via.placeholder.com/150?text=Info+Session",
    },
    // Demo Days
    {
      id: 25,
      title: "Showcase: Building AI-Powered Systems",
      description:
        "Teams present projects that integrate AI and ML into applications such as chatbots, recommendation systems, and predictive models.",
      category: "Demo Day",
      deliveryMode: "Online",
      image: "https://via.placeholder.com/150?text=Demo+Day",
    },
    {
      id: 26,
      title: "Architecting Tomorrow: Big Data Solutions in Action",
      description:
        "Teams showcase their Big Data architectures for real-world problems like traffic management or financial forecasting.",
      category: "Demo Day",
      deliveryMode: "In-Person",
      image: "https://via.placeholder.com/150?text=Demo+Day",
    },
  ];
  
  
  useEffect(() => {
    // Show loader when filters change
    setLoading(true);
    const timeout = setTimeout(() => {
      const filtered =
        activeCategory === "All"
          ? events
          : events.filter((event) => event.category === activeCategory);

      const furtherFiltered = filtered.filter(
        (event) =>
          (filters.topic === "All" || event.type === filters.topic) &&
          (filters.language === "All" || event.language === filters.language)
      );

      setDisplayedEvents(furtherFiltered.slice(0, visibleCount));
      setLoading(false);
    }, 1000); // 1 second loader delay

    return () => clearTimeout(timeout);
  }, [filters, activeCategory, visibleCount]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    setVisibleCount(12); // Reset visible count when filters change
  };

  const handleLoadMore = () => {
    setLoading(true);
    setTimeout(() => {
      setVisibleCount((prev) => prev + 12);
      setLoading(false);
    }, 1000); // Simulate load more delay
  };

  return (
    <div className="container mx-auto px-4 py-10 mt-20 mb-20">
      {/* Header */}
      <div className="bg-blue-100 text-white py-8 px-6 mt-10 rounded-lg mb-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-8">
          {/* Content Section */}
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <div className="relative inline-flex items-center px-4 py-2 bg-blue-100 rounded-full">
                <span className="absolute h-3 w-3 bg-blue-500 rounded-full animate-ping"></span>
                <span className="relative h-3 w-3 bg-blue-500 rounded-full mr-2"></span>
                <span className="text-blue-500 font-semibold text-sm">
                  LIVE EVENTS
                </span>
              </div>
            </div>
            <h1 className="text-3xl text-blue-500 lg:text-4xl font-bold mb-2">
              Explore Our Events
            </h1>
            <p className="text-lg text-blue-500">
              Discover workshops, talks, and demo days tailored to your
              interests.
            </p>
          </div>

          {/* Image Section */}
          <div className="relative">
            <img
              src="https://images.unsplash.com/photo-1628348068343-c6a848d2b6dd?q=80&w=600&h=400&auto=format&fit=crop"
              alt="Live Events"
              className="rounded-lg shadow-lg object-cover w-full h-auto"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center mb-6">
        {/* Filters */}
        <EventFilter
          topics={topics}
          languages={languages}
          activeFilters={filters}
          onFilterChange={handleFilterChange}
        />

        {/* View Toggle */}
        <ViewToggle view={view} onViewChange={setView} />
      </div>

      {/* Category Tabs */}
      <CategoryTabs
        categories={categories}
        activeCategory={activeCategory}
        onCategoryChange={setActiveCategory}
      />

      {/* Events */}
      {loading ? (
        <div className="text-center py-10">
          <div className="loader border-t-4 border-blue-500 w-12 h-12 rounded-full animate-spin"></div>
          <p className="mt-4 text-gray-600">Loading events...</p>
        </div>
      ) : (
        <div
          className={`grid ${
            view === "grid"
              ? "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
              : "grid-cols-1 gap-4"
          }`}
        >
          {displayedEvents.map((event) => (
            <EventCard key={event.id} event={event} view={view} />
          ))}
        </div>
      )}

      {/* Load More Button */}
      {!loading && displayedEvents.length < events.length && (
        <div className="text-center mt-8">
          <button
            onClick={handleLoadMore}
            className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default EventPage;
