import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import PersonalInfo from './registration/PersonalInfo';
import CareerGoals from './registration/CareerGoals';
import EventSelection from './registration/EventSelection';
import AttendanceConsent from './registration/AttendanceConsent';

type FormData = {
  personalInfo: {
    fullName: string;
    email: string;
    phone: string;
    residence: string;
    timezone: string;
    role: string;
  };
  careerGoals: string[];
  events: {
    bigDataAI: string[];
    coding: string[];
    itManagement: string[];
  };
  attendance: {
    mode: string;
    contactConsent: boolean;
    dataConsent: boolean;
  };
};

const initialFormData: FormData = {
  personalInfo: {
    fullName: '',
    email: '',
    phone: '',
    residence: '',
    timezone: '',
    role: '',
  },
  careerGoals: [],
  events: {
    bigDataAI: [],
    coding: [],
    itManagement: [],
  },
  attendance: {
    mode: '',
    contactConsent: false,
    dataConsent: false,
  },
};

export default function RegistrationForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState<FormData>(initialFormData);

  const totalSteps = 4;

  const handleNext = () => {
    setCurrentStep((prev) => Math.min(prev + 1, totalSteps));
  };

  const handlePrev = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Handle form submission
  };

  const updateFormData = (section: keyof FormData, data: any) => {
    setFormData((prev) => ({
      ...prev,
      [section]: data,
    }));
  };

  return (
    <div className="w-full bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="min-w-full mx-auto">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          {/* Header */}
          <div className="bg-blue-600 px-6 py-8 text-white">
            <h1 className="text-3xl font-bold">Registration Form</h1>
            <p className="mt-2 text-blue-100">
              Prepare for Today's IT Needs: Harness Big Data, AI, and ML
            </p>
          </div>

          {/* Progress Bar */}
          <div className="px-6 pt-4">
            <div className="flex items-center justify-between mb-2">
              {Array.from({ length: totalSteps }).map((_, index) => (
                <React.Fragment key={index}>
                  <div
                    className={`w-8 h-8 rounded-full flex items-center justify-center ${
                      index + 1 <= currentStep
                        ? 'bg-blue-600 text-white'
                        : 'bg-gray-200 text-gray-600'
                    }`}
                  >
                    {index + 1}
                  </div>
                  {index < totalSteps - 1 && (
                    <div
                      className={`flex-1 h-1 mx-2 ${
                        index + 1 < currentStep ? 'bg-blue-600' : 'bg-gray-200'
                      }`}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>

          <form onSubmit={handleSubmit} className="p-6">
            {/* Form Steps */}
            <div className="space-y-6">
              {currentStep === 1 && (
                <PersonalInfo
                  data={formData.personalInfo}
                  onChange={(data) => updateFormData('personalInfo', data)}
                />
              )}
              {currentStep === 2 && (
                <CareerGoals
                  data={formData.careerGoals}
                  onChange={(data) => updateFormData('careerGoals', data)}
                />
              )}
              {currentStep === 3 && (
                <EventSelection
                  data={formData.events}
                  onChange={(data) => updateFormData('events', data)}
                />
              )}
              {currentStep === 4 && (
                <AttendanceConsent
                  data={formData.attendance}
                  onChange={(data) => updateFormData('attendance', data)}
                />
              )}
            </div>

            {/* Navigation Buttons */}
            <div className="mt-8 flex justify-between">
              <button
                type="button"
                onClick={handlePrev}
                className={`inline-flex items-center px-4 py-2 rounded-md text-sm font-medium ${
                  currentStep === 1
                    ? 'text-gray-400 cursor-not-allowed'
                    : 'text-blue-600 hover:text-blue-700'
                }`}
                disabled={currentStep === 1}
              >
                <ChevronLeft className="w-5 h-5 mr-1" />
                Previous
              </button>
              {currentStep < totalSteps ? (
                <button
                  type="button"
                  onClick={handleNext}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Next
                  <ChevronRight className="w-5 h-5 ml-1" />
                </button>
              ) : (
                <button
                  type="submit"
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  Submit Registration
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}