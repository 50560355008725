import React from 'react';
import { ResumeData } from '../../../types/resume';
import Header from '../resume/Header';
import Skills from '../resume/Skills';
import Experience from '../resume/Experience';
import Education from '../resume/Education';
import Projects from '../resume/Projects';
import { useLocation } from 'react-router-dom';

interface Props {
  data: ResumeData;
}

export default function LiveResume() {
  const location = useLocation();
  const { data } = location.state as { data: ResumeData };

  return (
<div className="container mx-auto px-4 sm:px-6 lg:px-8">
<div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <Header
        name={data.personalInfo.name}
        title={data.personalInfo.title}
        location={data.personalInfo.location}
        email={data.contactInfo.email}
        phone={data.contactInfo.phone}
        linkedin={data.contactInfo.linkedin}
        github={data.contactInfo.github}
      />
      <div className="p-6 space-y-8">
        <Skills skills={data.skills} />
        <Experience experiences={data.workExperience} />
        <Education education={data.education} />
        <Projects projects={data.projects} />
      </div>
    </div>
    </div>
  );
}